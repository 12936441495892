import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroWithTitle from '../components/HeroWithTitle';
import Button from '../components/Button';
import {
  introStyle,
  withFrontUnderline,
  h2Style,
  h1Style,
} from '../styles/global';
import breakpoints from '../styles/breakpoints';
import IconCalendar from '../images/IconCalendar';
import StampHeart from '../images/StampHeart';
import colors from '../styles/colors';
import WithSidebar from '../components/WithSidebar';
import breakpointsRange from '../utils/breakpointsRange';
import { lessThan, greaterThan } from '../utils/mediaQuery';

import vectorListened from '../images/vectorListened.svg';
import vectorPostOperation from '../images/vectorPostOperation.svg';
import vectorPreOperation from '../images/vectorPreOperation.svg';
import vectorPreConsultation from '../images/vectorPreConsultation.svg';
import { useContactForm } from '../contexts/contactFormContext';

const IntroSection = styled.section`
  max-width: 1440px;
  margin: auto;

  text-align: center;

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [40, 120] },
      { prop: 'marginBottom', sizes: [68, 140] },
      { prop: 'paddingLeft', sizes: [28, 40, undefined, 140] },
      { prop: 'paddingRight', sizes: [28, 40, undefined, 140] },
    ],
    breakpoints.spacings
  )};
`;

const TitleWrapper = styled.div`
  display: flex;

  text-transform: uppercase;

  h1 {
    flex-basis: 0;

    margin: 0;

    line-height: ${35 / 30};

    word-break: initial;

    ${breakpointsRange(
      [{ prop: 'fontSize', sizes: [30, 56] }],
      breakpoints.fonts
    )};
  }
`;

const DetailSection = styled.section`
  background-color: ${colors.veryLightPink};

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [68, 140] },
      { prop: 'marginBottom', sizes: [68, 140] },
    ],
    breakpoints.spacings
  )};
`;

const DetailContainer = styled(WithSidebar)`
  max-width: 1440px;
  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [68, 134] },
      { prop: 'paddingBottom', sizes: [88, 140] },
      { prop: 'paddingRight', sizes: [28, 40, 40, 120] },
      { prop: 'paddingLeft', sizes: [28, 40, 40, 120] },
    ],
    breakpoints.spacings
  )};
`;

const DetailIcon = styled.img`
  min-width: 260px;
`;

const DetailTextWrapper = styled.div`
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const DetailButton = styled(Button)`
  ${breakpointsRange(
    [{ prop: 'marginTop', sizes: [48, 68] }],
    breakpoints.fonts
  )};
`;

const AdvicesSection = styled.section`
  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [80, 188] },
      { prop: 'marginBottom', sizes: [68, 140] },
      { prop: 'paddingLeft', sizes: [28, 40] },
      { prop: 'paddingRight', sizes: [28, 40] },
    ],
    breakpoints.spacings
  )};
`;

const AdvicesSectionTitle = styled.h2`
  ${h1Style};

  margin: 0 auto;

  color: ${colors.black};

  text-align: center;

  ${breakpointsRange(
    [{ prop: 'marginBottom', sizes: [40, 104] }],
    breakpoints.spacings
  )};
`;

const AdviceList = styled.ul`
  display: flex;
  max-width: 1440px;

  margin: auto;
  padding: 0;

  list-style: none;

  ${lessThan(breakpoints[4])} {
    flex-flow: column;
    max-width: 480px;
  }
`;

const AdviceButton = styled(Button)`
  && {
    min-width: initial;
  }
`;

const AdviceCard = styled.li`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: flex-start;
  max-width: 480px;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [48, 64] },
      { prop: 'paddingBottom', sizes: [48, 64] },
      { prop: 'paddingRight', sizes: [48, 68] },
      { prop: 'paddingLeft', sizes: [48, 68] },
    ],
    breakpoints.spacings
  )};
  ${breakpointsRange(
    [
      { prop: 'fontSize', sizes: [25, 38] },
      { prop: 'paddingBottom', sizes: [48, 64] },
      { prop: 'paddingRight', sizes: [48, 68] },
      { prop: 'paddingLeft', sizes: [48, 68] },
    ],
    breakpoints.fonts
  )};

  ${lessThan(breakpoints[4])} {
    align-items: center;

    text-align: center;
  }

  :nth-child(1) {
    background-color: ${colors.robinEggBlue};
  }

  :nth-child(2) {
    background-color: ${colors.azure};
  }

  :nth-child(3) {
    color: ${colors.white};

    background-color: ${colors.twilightBlue};
  }
`;

const AdviceImageWrapper = styled.div`
  flex-grow: 1;
`;

const AdviceImage = styled.img``;

const AdviceTitle = styled.h3`
  font-size: 1em;
  line-height: ${48 / 38};

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [40, 48] },
      { prop: 'marginBottom', sizes: [32, 52] },
    ],
    breakpoints.spacings
  )};

  span {
    ${greaterThan(998)} {
      white-space: nowrap;
    }
  }
`;

const EspacePatientPage = ({ data }) => {
  const { open } = useContactForm();

  const {
    headerImageMobile: {
      childImageSharp: { fluid: headerImageMobile },
    },
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
  } = data;

  const handleOpenContactForm = () => {
    open();
  };

  return (
    <Layout hasNoHeaderSpacer>
      <SEO
        title='Espace Patient'
        description='Vous venez rencontrer l’un de nos spécialistes des cliniques Maxillo Québec Lebourgneuf et Lévis, voici l’espace patient qui vous est dédié.'
      />

      <HeroWithTitle
        pictureSmall={headerImageMobile}
        pictureLarge={headerImageDesktop}
        renderStamp={<StampHeart />}
      >
        <TitleWrapper>
          <h1>Espace patient</h1>
        </TitleWrapper>
      </HeroWithTitle>

      <IntroSection>
        <p css={introStyle}>
          La mission des spécialistes de la clinique Maxillo Québec est de vous
          traiter avec humanisme, empathie et passion. Vous serez rassurés par
          l’accueil chaleureux de notre personnel à l’écoute et notre salle
          d’attente des plus reposantes.
        </p>

        <Button onClick={handleOpenContactForm} outlined primary>
          Devenir patient chez nous
        </Button>
      </IntroSection>

      <DetailSection>
        <DetailContainer
          align='center'
          contentMin='54%'
          spaceVertical={[56, 104]}
          spaceHorizontal={[56, 104]}
          renderSidebar={<DetailIcon src={vectorListened} />}
        >
          <DetailTextWrapper>
            <h2 css={[h2Style, withFrontUnderline]}>
              Offrir les meilleurs&nbsp;soins commence par une bonne écoute.
            </h2>

            <p>
              Votre chirurgien buccal et maxillo-facial de même que votre
              pathologiste de la clinique Maxillo Québec vous écouteront et vous
              porteront toute leur attention dans la discrétion et la
              confidentialité la plus totale, de manière à vous offrir les
              meilleurs soins et services en chirurgie buccale et
              maxillo-faciale. Vous êtes ainsi assurés de pouvoir compter sur la
              compétence et la compassion de notre équipe.
            </p>

            <DetailButton
              onClick={handleOpenContactForm}
              primary
              renderIcon={<IconCalendar />}
            >
              Prendre un rendez-vous
            </DetailButton>
          </DetailTextWrapper>
        </DetailContainer>
      </DetailSection>

      <AdvicesSection>
        <AdvicesSectionTitle>
          Quelques conseils pour nos&nbsp;patients
        </AdvicesSectionTitle>

        <AdviceList>
          <AdviceCard>
            <AdviceImageWrapper>
              <AdviceImage
                src={vectorPreConsultation}
                css={breakpointsRange(
                  [
                    {
                      prop: 'width',
                      sizes: [104, 138],
                      bases: [25, 38],
                      unit: 'em',
                    },
                  ],
                  breakpoints.fonts
                )}
              />
            </AdviceImageWrapper>

            <AdviceTitle>1. Première consultation</AdviceTitle>

            <AdviceButton to='/premiere-consultation' outlined>
              En savoir plus
            </AdviceButton>
          </AdviceCard>

          <AdviceCard>
            <AdviceImageWrapper>
              <AdviceImage
                src={vectorPreOperation}
                css={breakpointsRange(
                  [
                    {
                      prop: 'width',
                      sizes: [89, 118],
                      bases: [25, 38],
                      unit: 'em',
                    },
                  ],
                  breakpoints.fonts
                )}
              />
            </AdviceImageWrapper>

            <AdviceTitle>
              2. Avant <span>mon opération</span>
            </AdviceTitle>

            <AdviceButton to='/conseils-preoperatoires' outlined>
              En savoir plus
            </AdviceButton>
          </AdviceCard>

          <AdviceCard>
            <AdviceImageWrapper>
              <AdviceImage
                src={vectorPostOperation}
                css={breakpointsRange(
                  [
                    {
                      prop: 'width',
                      sizes: [89, 118],
                      bases: [25, 38],
                      unit: 'em',
                    },
                  ],
                  breakpoints.fonts
                )}
              />
            </AdviceImageWrapper>

            <AdviceTitle>
              3. Après <span>mon opération</span>
            </AdviceTitle>

            <AdviceButton to='/conseils-postoperatoires' white outlined>
              En savoir plus
            </AdviceButton>
          </AdviceCard>
        </AdviceList>
      </AdvicesSection>
    </Layout>
  );
};

export default EspacePatientPage;

EspacePatientPage.propTypes = {
  data: PropTypes.shape({
    headerImageDesktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    headerImageMobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    headerImageDesktop: file(
      name: { eq: "img-header-EspacePatient-2520x2160" }
    ) {
      ...HeroWithTitleLargePictureData
    }

    headerImageMobile: file(
      name: { eq: "img-header-EspacePatient-2220x2766" }
    ) {
      ...HeroWithTitleSmallPictureData
    }
  }
`;
