// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampHeart = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M21 75L3.7 75l.9-2.6 8.5.1 6 .3-5-3.4-6.8-5.1.8-2.6L22 72.1z' />
      <path d='M27.7 60.5a5.8 5.8 0 01-1.5 1.8 5 5 0 01-2 .9 6 6 0 01-2.3 0 9 9 0 01-4.7-2.7 6 6 0 01-1.1-2 4.7 4.7 0 01-.3-2.1 5.7 5.7 0 01.8-2.3 5.8 5.8 0 011.5-1.8 4.8 4.8 0 012-.9 6 6 0 012.4 0 8.3 8.3 0 012.5 1 8.4 8.4 0 012 1.7 6 6 0 011.3 2 4.9 4.9 0 01.2 2.2 6 6 0 01-.8 2.2zm-1.8-1a2.9 2.9 0 00.3-2.3 3.3 3.3 0 00-1.7-2l-1.8-1a3.3 3.3 0 00-2.5-.4 2.8 2.8 0 00-1.7 1.4 2.9 2.9 0 00-.4 2.2 3.3 3.3 0 001.7 2l1.8 1a3.3 3.3 0 002.5.5 2.9 2.9 0 001.8-1.5z' />
      <path d='M35.9 45.1a4.4 4.4 0 01.3 1 3 3 0 01.1 1 3.1 3.1 0 01-.2 1 4.2 4.2 0 01-.8 1.2 3.7 3.7 0 01-2.8 1.4 5.3 5.3 0 01-3.4-1.5l-6-5 1.8-2 5.6 4.8c1.5 1.3 2.7 1.3 3.7.1a3.9 3.9 0 00.6-.8 2.7 2.7 0 00.2-.9 2 2 0 000-.9 2 2 0 00-.6-.8L28 38.4l1.7-2 9.3 8-1.7 2z' />
      <path d='M45.7 38.7a6.4 6.4 0 01-2.6 1.5 6.7 6.7 0 01-2.7 0l.3-2.2a5.3 5.3 0 002 0 4 4 0 001.8-1 2.7 2.7 0 001-1.4 1.4 1.4 0 00-.4-1.2 1.4 1.4 0 00-1-.5 2.2 2.2 0 00-1.4.4l-1 .7a5.8 5.8 0 01-3 1 3.1 3.1 0 01-2.5-1.1 3.7 3.7 0 01-.8-1.5 3.5 3.5 0 010-1.5 4.4 4.4 0 01.6-1.5 7 7 0 013.6-2.8 6 6 0 012.3-.1l-.2 2.2a4.3 4.3 0 00-1.6 0 3.5 3.5 0 00-1.6.9 2.4 2.4 0 00-.9 1.3 1.4 1.4 0 00.4 1.2 1.3 1.3 0 001 .5 3.3 3.3 0 001.4-.5l1-.7a5.4 5.4 0 013-1 3.1 3.1 0 012.3 1.2 3.7 3.7 0 01.9 1.4 3.5 3.5 0 010 1.6 4.5 4.5 0 01-.6 1.6 7 7 0 01-1.3 1.5z' />
      <path d='M56.3 10l4 2-.8 1.5-3.7-1.3-1.4 3.6-1.6-.3L54 11zm6.5 17.6a5.8 5.8 0 01-2.4.5 4.9 4.9 0 01-2.1-.4 5.7 5.7 0 01-1.9-1.4A8.4 8.4 0 0155 24a8.6 8.6 0 01-.7-2.6 5.5 5.5 0 01.2-2.3 4.8 4.8 0 011-2 6 6 0 012-1.3 5.6 5.6 0 012.4-.6 4.5 4.5 0 012 .5 5.6 5.6 0 011.8 1.4 8 8 0 011.3 2l.4.9-7.7 3.4.2.4a3.3 3.3 0 001.7 1.8 3.1 3.1 0 002.6-.2 3.5 3.5 0 001.5-1.3 4.2 4.2 0 00.7-1.7l2 .7a5 5 0 01-1.1 2.5 6.5 6.5 0 01-2.5 1.9zm-4.5-9.9a3.4 3.4 0 00-1 .7 2.8 2.8 0 00-.6 1 3 3 0 00-.1 1.2 4.2 4.2 0 00.3 1.3l.1.1 5.2-2.4v-.2a3.4 3.4 0 00-1.7-1.7 2.5 2.5 0 00-2.2 0z' />
      <path d='M74.7 22.7a2.7 2.7 0 01-2.2-.1 2.7 2.7 0 01-1.2-1.7l-2-7.3-1.8.6-.6-2 1-.3a1.1 1.1 0 00.8-.5 1.4 1.4 0 000-1l-.6-2 2.2-.7 1 3.2 2.4-.7.6 2-2.5.7 2.2 7.8 2.3-.6.6 2z' />
      <path d='M85.6 20.6a6 6 0 01-2.4-.2 5 5 0 01-2-1 5.8 5.8 0 01-1.3-1.9 8.3 8.3 0 01-.7-2.6 8.6 8.6 0 010-2.6A6 6 0 0180 10a5 5 0 011.6-1.5 6 6 0 012.3-.7 5.6 5.6 0 012.4.2 4.7 4.7 0 011.8 1 5.6 5.6 0 011.3 1.9 8.1 8.1 0 01.6 2.3l.2 1-8.4 1v.5a3.3 3.3 0 001.1 2.2 3.1 3.1 0 002.5.6 3.5 3.5 0 001.8-.8 4.5 4.5 0 001.2-1.5l1.7 1.3a5 5 0 01-1.8 2 6.5 6.5 0 01-2.8 1zM84.2 9.8a3.2 3.2 0 00-1.2.4 2.5 2.5 0 00-.8.8 3 3 0 00-.5 1 3.9 3.9 0 000 1.3v.2l5.7-.7v-.3a3.4 3.4 0 00-1-2.2 2.5 2.5 0 00-2.2-.5z' />
      <path d='M98.9 19.7A6.3 6.3 0 0196 19a6.5 6.5 0 01-2-1.8l1.7-1.4a5.3 5.3 0 001.5 1.3 4 4 0 002 .5 2.7 2.7 0 001.6-.4 1.6 1.6 0 00.2-2.2 2.2 2.2 0 00-1.4-.6l-1-.1a5.7 5.7 0 01-3-1.2 3 3 0 01-1-2.6A3.8 3.8 0 0195 9a3.5 3.5 0 011-1.1 4.3 4.3 0 011.4-.8 6.1 6.1 0 011.8-.2 5.6 5.6 0 014.6 2l-1.7 1.5a4 4 0 00-1.2-1 3.6 3.6 0 00-1.7-.4 2.5 2.5 0 00-1.6.4 1.4 1.4 0 00-.5 1 1.3 1.3 0 00.4 1.1 3.2 3.2 0 001.4.6l1.1.2a5.5 5.5 0 013 1.3 3 3 0 01.9 2.4 3.7 3.7 0 01-.4 1.6 3.6 3.6 0 01-1 1.2 4.6 4.6 0 01-1.6.8 6.7 6.7 0 01-2 .2z' />
      <path d='M123.2 23.4a2 2 0 01-1.4-1 2.6 2.6 0 01-.2-1.6h-.1a3 3 0 01-1.7 1.4 4.2 4.2 0 01-2.4 0 4 4 0 01-2.6-1.8 3.5 3.5 0 01-.2-2.9 3.1 3.1 0 012-2.3 7 7 0 014 .2l2.1.6.3-1a2.4 2.4 0 00-.1-1.9 2.6 2.6 0 00-1.6-1.1 3 3 0 00-1.9 0 4.2 4.2 0 00-1.4.8l-1.1-1.7a5 5 0 012.1-1.2 5.5 5.5 0 013 .1 5.3 5.3 0 013.2 2.1 4 4 0 01.4 3.4l-1.7 6 1.2.3-.5 2zm-4.6-3a3.5 3.5 0 002 0 1.6 1.6 0 001.2-1.1l.5-1.7-2-.6c-1.8-.5-2.8-.2-3 .9l-.2.4a1.4 1.4 0 00.2 1.3 2.5 2.5 0 001.3.8z' />
      <path d='M135.7 26.6h-.1a5.2 5.2 0 01-.8.6 3.1 3.1 0 01-1 .3 3.4 3.4 0 01-1 0 4.8 4.8 0 01-1.4-.4 3.7 3.7 0 01-2-2.4 5.3 5.3 0 01.6-3.7l3.6-7 2.3 1.2-3.5 6.6c-.8 1.7-.6 3 .8 3.7a3.7 3.7 0 001 .3 2.9 2.9 0 00.9 0 2 2 0 00.8-.3 2 2 0 00.6-.8l3.8-7.3 2.3 1.2-5.6 11-2.3-1.2z' />
      <path d='M150.2 39.3a6.1 6.1 0 01-1.5-2 5 5 0 01-.5-2 6 6 0 01.5-2.3 9 9 0 011.5-2.3 8.4 8.4 0 012-1.7 5.7 5.7 0 012.1-.8 4.7 4.7 0 012.2.1 6.1 6.1 0 012 1.2 5.2 5.2 0 011.8 2.6 4.8 4.8 0 01-.1 2.8l-2.3-.6a2.5 2.5 0 00-.8-3.1 2.6 2.6 0 00-2.1-.8 3.5 3.5 0 00-2 1.3l-1.5 1.7a3.5 3.5 0 00-1 2.2 2.6 2.6 0 001 2 2.8 2.8 0 001.8.8 4 4 0 001.7-.4l.8 2a5 5 0 01-2.9.6 5.4 5.4 0 01-2.7-1.3z' />
      <path d='M159.3 48.6a6 6 0 01-1-2.1 5 5 0 01-.1-2.2 5.9 5.9 0 01.9-2.1 8 8 0 011.8-2 8.4 8.4 0 012.3-1.4 5.9 5.9 0 012.4-.3 5 5 0 012 .6 6 6 0 011.9 1.5 6 6 0 011 2.1 4.8 4.8 0 01.1 2.2 6 6 0 01-.9 2.2 8.2 8.2 0 01-1.8 2 8.4 8.4 0 01-2.3 1.3 5.7 5.7 0 01-2.3.4 4.8 4.8 0 01-2.2-.6 5.8 5.8 0 01-1.8-1.6zm1.7-1.3a2.9 2.9 0 002 1.2 3.3 3.3 0 002.4-1l1.6-1.2a3.3 3.3 0 001.4-2.1 2.8 2.8 0 00-.7-2.2 2.8 2.8 0 00-2-1.2 3.3 3.3 0 00-2.3.9l-1.6 1.3a3.3 3.3 0 00-1.4 2.1 2.9 2.9 0 00.6 2.2z' />
      <path d='M167.5 60.5a6 6 0 01-.8-2.2 4.8 4.8 0 01.3-2.2 5.8 5.8 0 011.2-2 8.8 8.8 0 012-1.7 8.4 8.4 0 012.6-1 5.9 5.9 0 012.3 0 5 5 0 012 .9 6 6 0 011.6 1.8 5.6 5.6 0 01.7 2.3 4.6 4.6 0 01-.2 2.2 5.7 5.7 0 01-1.2 1.8 8.3 8.3 0 01-1.9 1.5l-.8.5L171 55l-.3.2A3.3 3.3 0 00169 57a3.1 3.1 0 00.4 2.6 3.5 3.5 0 001.4 1.4 4.2 4.2 0 001.8.5l-.6 2a4.9 4.9 0 01-2.5-.8 6.4 6.4 0 01-2-2.3zm9.4-5.4a3.2 3.2 0 00-.8-1 2.6 2.6 0 00-1-.4 2.8 2.8 0 00-1.2 0 3.9 3.9 0 00-1.2.5h-.2l2.9 5h.2a3.4 3.4 0 001.6-1.8 2.5 2.5 0 00-.3-2.3z' />
      <path d='M176.4 75.2a4.6 4.6 0 01-1-.2 3 3 0 01-1-.4 3.3 3.3 0 01-.7-.8 4.3 4.3 0 01-.6-1.2 3.7 3.7 0 01.2-3.2 5.3 5.3 0 013-2.1l7.4-2.5.8 2.4-7 2.4q-2.8 1-2 3.2a3.2 3.2 0 00.4.9 2.4 2.4 0 00.7.6 2 2 0 00.8.4 1.8 1.8 0 001-.1l7.8-2.7.8 2.5-11.7 4-.8-2.5z' />
      <path d='M176.4 83l12-2 .6 2.5-2.4.4a3.3 3.3 0 013 3v.7l-2.4.4-.2-1a4.6 4.6 0 00-.9-2.2 1.6 1.6 0 00-1.6-.6l-7.7 1.4z' />
      <path d='M179.8 107.8v-.1a3.4 3.4 0 01-1.6-1.4 3.8 3.8 0 01-.5-2.2 4.3 4.3 0 012-3.5 9.4 9.4 0 019.5.7 4.3 4.3 0 011.4 3.8 3.7 3.7 0 01-.8 2 3.3 3.3 0 01-1.8 1.2v.1l7.2.6-.2 2.6-17.4-1.4.2-2.5zm.1-2.8a3.3 3.3 0 00.4 2 1.8 1.8 0 001.5 1l4.2.3a1.8 1.8 0 001.6-.7 3.3 3.3 0 00.7-2 2.8 2.8 0 00-.7-2.2 3.2 3.2 0 00-2.2-1l-2.1-.1a3.2 3.2 0 00-2.4.6 2.8 2.8 0 00-1 2.1z' />
      <path d='M175.4 119.7a5.8 5.8 0 011-2.2 5 5 0 011.8-1.4 5.9 5.9 0 012.3-.5 8.5 8.5 0 012.6.4 8.8 8.8 0 012.5 1 5.7 5.7 0 011.6 1.7 4.8 4.8 0 01.8 2 6 6 0 01-.2 2.4 5.5 5.5 0 01-1.1 2.2 4.7 4.7 0 01-1.8 1.3 5.7 5.7 0 01-2.1.4 8.3 8.3 0 01-2.4-.3l-1-.3 2.4-8-.4-.2a3.3 3.3 0 00-2.5.2 3.1 3.1 0 00-1.5 2 3.5 3.5 0 000 2 4.3 4.3 0 00.9 1.7l-1.9 1a4.9 4.9 0 01-1.2-2.4 6.5 6.5 0 01.2-3zm10.4 3a3 3 0 00.1-1.3 2.6 2.6 0 00-.3-1 2.9 2.9 0 00-.9-1 3.5 3.5 0 00-1.1-.5h-.2l-1.6 5.5h.3a3.4 3.4 0 002.4 0 2.5 2.5 0 001.3-1.8z' />
      <path d='M170.3 135l10.6 6.2-1.3 2.2-1.7-1-.1.1a4.2 4.2 0 01.8 1.9 3.5 3.5 0 01-.5 2.3 3.6 3.6 0 01-2.5 1.9 5.3 5.3 0 01-3.6-.9l-6.8-3.8 1.3-2.3 6.5 3.7q2.5 1.5 3.6-.5a3.6 3.6 0 00.4-1 3 3 0 000-.9 2 2 0 00-.2-.8 1.8 1.8 0 00-.7-.7l-7.1-4.1z' />
      <path d='M159.2 151.3a5.8 5.8 0 011.9-1.5 5 5 0 012.1-.5 5.8 5.8 0 012.3.5 8.4 8.4 0 012.3 1.4 8 8 0 011.7 2 5.8 5.8 0 01.8 2.2 4.7 4.7 0 01-.2 2.2 6 6 0 01-1.2 2 6.1 6.1 0 01-1.8 1.5 5 5 0 01-2.2.5 5.9 5.9 0 01-2.3-.5 8.3 8.3 0 01-2.2-1.4 8.2 8.2 0 01-1.8-2 6 6 0 01-.8-2.2 4.8 4.8 0 01.2-2.2 5.8 5.8 0 011.2-2zm1.6 1.4a2.9 2.9 0 00-.7 2.1 3.3 3.3 0 001.3 2.2l1.5 1.4a3.4 3.4 0 002.4 1 3.2 3.2 0 002.8-3.2 3.3 3.3 0 00-1.3-2.2l-1.6-1.4a3.3 3.3 0 00-2.4-1 2.9 2.9 0 00-2 1z' />
      <path d='M150.3 160.7a2.4 2.4 0 013.8.3l5 5.7 1.4-1.3 1.3 1.6-.8.7a1.1 1.1 0 00-.4.8 1.4 1.4 0 00.4.8l1.4 1.7-1.8 1.5-2.1-2.6-2 1.7-1.4-1.6 2-1.7-5.3-6-1.8 1.5-1.4-1.6z' />
      <path d='M145.3 164.7l7.1 10-2 1.5-1.4-1.9h-.1a3.3 3.3 0 010 2 3.4 3.4 0 01-1.4 2l-.5.4-1.5-2 .8-.6a4.5 4.5 0 001.7-1.8 1.6 1.6 0 00-.2-1.6l-4.6-6.4z' />
      <path d='M132.4 172.2a5.9 5.9 0 012.4-.6 4.9 4.9 0 012.1.5 5.5 5.5 0 011.9 1.4 8.2 8.2 0 011.4 2.2 8.6 8.6 0 01.7 2.6 5.7 5.7 0 01-.1 2.3 4.8 4.8 0 01-1.1 2 6 6 0 01-2 1.3 5.7 5.7 0 01-2.4.5 4.8 4.8 0 01-2-.4 5.8 5.8 0 01-1.8-1.4 8 8 0 01-1.3-2l-.4-.9 7.7-3.5-.2-.3a3.3 3.3 0 00-1.7-1.8 3.1 3.1 0 00-2.6.2 3.5 3.5 0 00-1.5 1.2 4.3 4.3 0 00-.7 1.8l-2-.8a5 5 0 011.1-2.4 6.4 6.4 0 012.5-2zm4.5 9.9a3 3 0 001-.8 2.7 2.7 0 00.6-1 2.9 2.9 0 00.1-1.1 4.1 4.1 0 00-.3-1.3l-.1-.1-5.2 2.3.1.2a3.3 3.3 0 001.6 1.8 2.5 2.5 0 002.2 0z' />
      <path d='M119.5 190.2l-2.6.5-.3-2h-.1a3.4 3.4 0 01-1 1.9 3.8 3.8 0 01-2 1 4.3 4.3 0 01-3.9-1 9.5 9.5 0 01-1.6-9.3 4.3 4.3 0 013.2-2.3 3.6 3.6 0 012.2.2 3.4 3.4 0 011.6 1.4h.1L114 174l2.5-.4zm-7.1-9.2a2.8 2.8 0 00-2 1.2 3.3 3.3 0 00-.4 2.4l.4 2a3.2 3.2 0 001.2 2.2 2.8 2.8 0 002.2.5 3.3 3.3 0 001.9-1 1.8 1.8 0 00.5-1.6l-.7-4.1a1.8 1.8 0 00-1.1-1.4 3.3 3.3 0 00-2-.2z' />
      <path d='M102.3 180.3l.3 12.3h-2.5l-.1-2.3h-.1a3.3 3.3 0 01-1 1.6 3.4 3.4 0 01-2.3.8h-.7v-2.5h1a4.7 4.7 0 002.3-.5 1.6 1.6 0 00.8-1.5l-.2-7.8z' />
      <path d='M83.3 179.2a2 2 0 011.5.8 2.7 2.7 0 01.5 1.5h.1a3 3 0 011.5-1.5 4.2 4.2 0 012.4-.3A4 4 0 0192 181a3.5 3.5 0 01.7 2.8 3.1 3.1 0 01-1.7 2.5 6.8 6.8 0 01-4 .5l-2.2-.3-.1 1a2.4 2.4 0 00.4 1.8 2.6 2.6 0 001.8.8 3 3 0 001.8-.2 4 4 0 001.3-1l1.4 1.5a5 5 0 01-2 1.6 5.6 5.6 0 01-3 .3 5.4 5.4 0 01-3.4-1.6 4 4 0 01-.9-3.3l.8-6.1-1.3-.2.3-2.1zm5 2.3a3.4 3.4 0 00-2 .3 1.6 1.6 0 00-1 1.3l-.2 1.8 2.2.3c1.7.2 2.7-.3 2.8-1.3v-.5a1.5 1.5 0 00-.4-1.3 2.5 2.5 0 00-1.4-.6z' />
      <path d='M75.1 177.2a2.7 2.7 0 011.8 1.2 2.7 2.7 0 010 2l-2 7.3 2 .5-.6 2-1-.2a1.1 1.1 0 00-1 0 1.4 1.4 0 00-.5.8l-.6 2-2.2-.5 1-3.3-2.6-.7.6-2 2.5.7 2.2-7.7-2.3-.7.6-2z' />
      <path d='M62.5 188.1a1.6 1.6 0 011 .8 1.3 1.3 0 010 1l-.1.4a1.4 1.4 0 01-.7.8 1.6 1.6 0 01-1.3-.1 1.5 1.5 0 01-.9-.8 1.4 1.4 0 010-1l.1-.4a1.4 1.4 0 01.7-.7 1.6 1.6 0 011.2 0zm2-1.5l-2.4-1 4.5-11.5 2.4 1z' />
      <path d='M54.4 170.2l.1.1a3.5 3.5 0 012-.8 3.7 3.7 0 012.2.5 4.2 4.2 0 012.3 3.3 9.4 9.4 0 01-4.7 8.2 4.3 4.3 0 01-4-.3 3.8 3.8 0 01-1.6-1.6 3.4 3.4 0 01-.3-2.2l-1 1.7L47 178l8.5-14.8 2.2 1.3zm2.5 1.4a3.4 3.4 0 00-2-.5 1.8 1.8 0 00-1.5.9l-2 3.6a1.8 1.8 0 00-.1 1.8 3.3 3.3 0 001.4 1.4 2.8 2.8 0 002.3.4 3.3 3.3 0 001.8-1.6l1-1.8a3.3 3.3 0 00.5-2.4 2.8 2.8 0 00-1.4-1.8z' />
      <path d='M42.2 161l.1.1a4.5 4.5 0 01.9-.3 3 3 0 011-.2 3.3 3.3 0 011 .3 4.4 4.4 0 011.2.7 3.7 3.7 0 011.4 3 5.2 5.2 0 01-1.5 3.3l-5 5.9-2-1.7 4.9-5.7q1.8-2.1 0-3.7a3.6 3.6 0 00-.8-.5 2.4 2.4 0 00-.8-.3 2 2 0 00-1 .1 1.8 1.8 0 00-.7.6l-5.4 6.2-2-1.7 8-9.2 2 1.6z' />
      <path d='M35.4 150.5a6.2 6.2 0 011 2.1 5 5 0 01.2 2.2 6 6 0 01-1 2.1 8.6 8.6 0 01-1.8 2 8.5 8.5 0 01-2.3 1.3 5.8 5.8 0 01-2.3.4 4.8 4.8 0 01-2.1-.6 5.8 5.8 0 01-1.9-1.6 5.5 5.5 0 01-1-2.2 4.7 4.7 0 010-2.1 5.7 5.7 0 01.9-2 8.3 8.3 0 011.6-1.8l.8-.6 5.2 6.7.3-.3a3.3 3.3 0 001.3-2 3.1 3.1 0 00-.8-2.5 3.5 3.5 0 00-1.5-1.2 4.3 4.3 0 00-1.9-.2l.2-2.1a4.9 4.9 0 012.7.4 6.6 6.6 0 012.4 2zm-8.6 6.7a3.3 3.3 0 001 .8 2.8 2.8 0 001 .3 3.2 3.2 0 001.3-.1 4.1 4.1 0 001.1-.7h.1l-3.5-4.6-.2.2a3.4 3.4 0 00-1.3 2 2.5 2.5 0 00.5 2z' />
      <path d='M18.3 143.9A88.8 88.8 0 017 100.8a90 90 0 011-13.8l2.3.3a86.8 86.8 0 009.8 55.6z' />
    </RoundContainer>
    <path d='M97.9 118.2l-16.5-15.6a9.8 9.8 0 01-.2-14.1 10.5 10.5 0 0114.5-.2l1.9 1.8 1.8-1.8a10.5 10.5 0 0114.5-.1 9.8 9.8 0 01.1 14zm-9.4-30.5a8.2 8.2 0 00-5.7 2.3 7.6 7.6 0 000 11l15 14.2 14.7-14.5a7.5 7.5 0 002.3-5.5 7.6 7.6 0 00-2.4-5.4 8.3 8.3 0 00-11.4.1l-3.4 3.3-3.4-3.3a8.2 8.2 0 00-5.7-2.2z' />
    <path d='M96.5 125.5h2.2v8.7h-2.2z' />
    <path d='M96.5 65.5h2.2v8.7h-2.2z' />
    <path d='M63.3 98.8H72v2.2h-8.7z' />
    <path d='M123.3 98.8h8.7v2.2h-8.7z' />
    <path transform='rotate(-44.9 76.4 121.1)' d='M72.1 120h8.7v2.2h-8.7z' />
    <path transform='rotate(-44.9 118.8 78.7)' d='M114.5 77.6h8.7v2.2h-8.7z' />
    <path transform='rotate(-45 76.4 78.7)' d='M75.3 74.3h2.2V83h-2.2z' />
    <path transform='rotate(-45 118.8 121.1)' d='M117.7 116.8h2.2v8.7h-2.2z' />
  </svg>
);

export default StampHeart;
